const LevelUp = () => import('@/components/modals/LevelUp');
import { mapGetters } from 'vuex';

export default {
  data() {
    return {
      userLevelStateOld  : 0,
      userLevelThreshold : 100,
    };
  },
  components : {
    LevelUp,
  },
  mounted() {
    this.userLevelStateOld =
      this.$store.getters['user/user'].userLevel.level;
  },
  computed : {
    ...mapGetters({
      levels : 'gamification/levels',
    }),
    level() {
      // eslint-disable-next-line no-undef
      const lvl = _.find(this.levels, (o) => {
        return o.level === this.userLevelStateNew;
      });

      return lvl || {};
    },
    userLevelStateNew() {
      return this.$store.getters['user/user'].userLevel.level;
    },
  },
  watch : {
    userLevelStateNew() {
      if (this.userLevelStateNew > this.userLevelStateOld) {
        this.showLevelUpModal();
        this.userLevelStateOld = this.userLevelStateNew;
      }
    },
  },
  methods : {

    /**
     * Show Level Up Modal
     */
    showLevelUpModal() {
      this.$modal.show(LevelUp,
        {
          level : this.level,
        },
        {
          height   : 'auto',
          adaptive : true,
          classes  : ['level-up-modal'],
          pivotY   : 0.2,
        });
    },

    /**
     * Add Exp points
     * @param eventType
     */
    addPoints(eventType) {
      this.$http.post('api/user/level', {
        id    : this.$store.getters['user/user'].user_id,
        event : eventType,
      }).then(() => {
        if (eventType === 'FIRST_VIDEO')
          this.updateFirstVideo();
        else {
          localStorage.levelUpAction = eventType === 'WATCHED_VIDEO' ?
            'AFTER_VIDEO' : eventType;
          this.$store.dispatch('user/getUpdatedUser');
        }
      });
    },

    /**
     * Update User's First Video
     */
    updateFirstVideo() {
      this.$http.put('api/user', {
        'updateFields' : {
          'first_video' : 1,
        },
      }).then(() => {
        localStorage.LevelUpAction = 'FIRST_VIDEO';
        this.$store.dispatch('user/getUpdatedUser');
      })
    },
  },
};