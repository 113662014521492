<template>
  <div>
    <!-- <h4>Music</h4> -->
    <b-skeleton-wrapper :loading="reqStatus == 'loading'">
      <template #loading>
        <b-skeleton-img
          height="50vh"
          animation="throb"
          no-aspect
        ></b-skeleton-img>
      </template>

      <div class="fave-container" v-if="favorites.length > 0">
        <b-row no-gutters align-v="stretch">
          <b-col
            class="text-center"
            cols="12" lg="4" xl="4"
            align-self="stretch">
            <div
              class="plist-container"
              :style="{height: `${plistContainerHeight}px`}">
              <img
                class="img-fluid playlist-thumb"
                src="../../assets/images/music/My-Favorites-Cover-Art.png"
                alt="Music Favorites"
              />
            </div>
          </b-col>
          <b-col
            class="favorites"
            :class="{'d-flex' : breakpoint === 'xl'}"
            cols="12"
            lg="8"
            xl="8"
            align-self="stretch">
            <ol
              class="songs"
              :style="{height: `${plistContainerHeight}px`}">
              <li
                class="song"
                :class="{
                  active : activeIndex === index && status === 'playing'
                }"
                v-for="(fave, index) in favorites"
                :key="fave.mobile_music_id">
                <b-row no-gutters>
                  <b-col cols="10">
                    <p v-if="breakpoint === 'xl'">
                      {{ fave.music_title | truncate(50) }}
                    </p>
                    <p v-else> {{ fave.music_title | truncate(30) }} </p>
                  </b-col>
                  <b-col class="text-right" cols="2">
                    <b-icon
                      class="delete-icon"
                      icon="trash-fill"
                      title="Remove Favorite"
                      @click.prevent="confirmDelete(index, fave)"
                    ></b-icon>
                  </b-col>
                </b-row>
                
                <vue-plyr
                  :id="`player-${fave.mobile_music_id}`"
                  :class="`${fave.mobile_music_id}`"
                  :key="fave.url"
                  :ref="`player-${fave.mobile_music_id}`">
                  <audio :id="fave.mobile_music_id">
                    <source
                      :src="fave.url"
                      type="audio/mp3"
                    />
                  </audio>
                </vue-plyr>
              </li>
            </ol>
          </b-col>
        </b-row>
      </div>
      <AppNoContent class="no-content" v-if="reqStatus === 'error'">
        <p class="mb-0 title">This space looks empty</p>
        <p class="mb-0">Fill it up by liking music from our
          <router-link to="/music">playlists</router-link>!
        </p>
      </AppNoContent>
    </b-skeleton-wrapper>
  
    <v-dialog />
  </div>
</template>

<script>
  import { mapGetters } from 'vuex';
  import BreakpointMixin from '@/mixins/BreakpointMixin';
  import FilterMixin from '@/mixins/FilterMixin';
  import LevelUpMixin from '@/mixins/LevelUpMixin';
  import MyMediaMixin from '@/mixins/MyMediaMixin';

  export default {
    mixins : [
      BreakpointMixin,
      FilterMixin,
      LevelUpMixin,
      MyMediaMixin,
    ],
    data() {
      return {
        status        : null,
        player        : null,
        currentTime   : null,
        activeSong    : {},
        activeIndex   : null,
        totalPlayed   : 0,
        thirtySecSent : false,
        totalSecSent  : false,
      }
    },
    computed : {
      ...mapGetters({
        favorites : 'profile/musicFavorites',
        profile   : 'profile/info',
        reqStatus : 'profile/musicFaveStatus',
      }),
    },
    components : {
      AppNoContent : () => import('@/components/layout/AppNoContent'),
    },
    watch : {
      currentTime(newVal, oldVal) {
        let diff = 0;
        if (newVal > oldVal)
          diff = newVal - oldVal;
        else
          diff = oldVal - newVal;
        
        if (diff < 1 && this.status === 'playing')
          this.totalPlayed = this.totalPlayed + diff;
      },
    },
    methods : {

      /**
       * Show Confirmation Dialog
       * @param data
       * @param index
       */
      confirmDelete(index, data) {
        this.$modal.show('dialog', {
          title   : 'Music Favorite',
          text    : `Remove <b>${data.music_title}</b>?`,
          buttons : [
            {
              title   : 'Yes',
              handler : () => {
                this.deleteFavorite(index, data);
                this.$modal.hide('dialog');
              },
            },
            {
              title : 'No',
            },
          ],
        });
      },

      /**
       * Delete Music from Favorite
       * @param data
       * @param index
       */
      deleteFavorite(index, data) {
        if (this.activeIndex === index) {
          this.activeSong = {};
          this.activeIndex = null;
        }

        const params = { musicId : data.mobile_music_id };
        if (this.profile.user_profiles_id)
          params.profileId = this.profile.user_profiles_id;
        
        this.$http.delete('api/mymusic', {
          data : params,
        }).then(() => {
          this.$nextTick(() => {
            this.$store.dispatch('profile/getMusicFavorites');
          });
          this.$notify({
            group : 'notif',
            type  : 'success',
            title : 'Success!',
            text  : data.music_title + ' has been removed from Favorites',
          });
        }).catch(() => {
          this.$notify({
            group : 'notif',
            type  : 'error',
            title : 'Failed!',
            text  : 'Oops! Something went wrong.',
          });
        });
      },

      /**
       * Play Next Song in the list
       */
      playNextSong() {
        const audios = document.getElementsByTagName('audio');
        const nextIndex = this.activeIndex + 1;
        if (this.favorites.length > nextIndex)
          audios[nextIndex].play();
      },

      gtag30sec(){
        this.$gtag.event('music_30s_listen', {
          'media_id'    : this.activeSong.mobile_music_id,
          'media_title' : this.activeSong.music_title,
          'platform'    : 'website',
          'environment' : process.env.VUE_APP_ENVIRONMENT,
        })
      },
      gtagtotalsec(){
        this.$gtag.event('music_total_listen', {
          'media_id'              : this.activeSong.mobile_music_id,
          'media_title'           : this.activeSong.music_title,
          'platform'              : 'website',
          'environment'           : process.env.VUE_APP_ENVIRONMENT,
          'music_listen_duration' : this.totalPlayed,
        })
      },
      gtagStartListen(){
        this.$gtag.event('music_start_listen', {
          'media_id'    : this.activeSong.mobile_music_id,
          'media_title' : this.activeSong.music_title,
          'platform'    : 'website',
          'environment' : process.env.VUE_APP_ENVIRONMENT,
        })
      },
    },
    created() {
      window.addEventListener('beforeunload', e => {
        if (this.totalPlayed >= 30) {
          if (!this.thirtySecSent)
            this.gtag30sec();
        }

        if (!this.totalSecSent)
          this.gtagtotalsec();

        delete e['returnValue'];
      });
    },
    mounted() {
      document.addEventListener('timeupdate', e => {
        this.status = 'playing';
        const currAudio = e.target.getElementsByTagName('audio')[0];
        this.currentTime = currAudio.currentTime;
      });

      document.addEventListener('playing', e => {
        //   console.log("...PLAYING triggered...");
        this.gtagStartListen();

        this.status = 'playing';

        //this.startStream();
        const currAudio = e.target.getElementsByTagName('audio')[0];
        const audios = document.getElementsByTagName('audio');
        this.player = currAudio;

        for (let i = 0; i < audios.length; i++) {
          if (audios[i] != currAudio)
            audios[i].pause();
          else if (audios[i] == currAudio && this.activeSong !=
            this.favorites[i]) {
            this.activeIndex = i;
            this.activeSong = this.favorites[i];
            this.totalPlayed = 0;

            this.$analytics.fbq.event('ContentView', {
              'page'        : 'music-player',
              'platform'    : 'website',
              'music_title' : this.activeSong.music_title,
              'music_id'    : this.activeSong.mobile_music_id,
            });
          }
        }
      }, false);

      document.addEventListener('pause', () => {
        //  console.log("...PAUSE triggered...");
        this.status = 'pause';
        
        //this.stopStream();
      }, false);

      document.addEventListener('seeking', () => {
        // console.log("...SEEKING triggered...");
        this.status = 'seeking';
      }, false);

      document.addEventListener('ended', () => {
        //  console.log("...ENDED triggered...");
        this.status = 'ended';
        
        //this.stopStream();
        if(this.totalPlayed >= 30){
          if(!this.thirtySecSent)
            this.gtag30sec();
        }

        if(!this.totalSecSent)
          this.gtagtotalsec();

        if (this.totalPlayed >= 60)
          this.addPoints('LISTENED_MUSIC');

        this.playNextSong();
      }, false);

      document.addEventListener('loadeddata', () => {
        this.$analytics.fbq.event('ContentView', {
          'page'          : 'favorites',
          'platform'      : 'website',
          'playlist_name' : 'music favorites',
        })
      }, false);
    },
    beforeDestroy() {
      if (this.totalPlayed >= 30) {
        if (!this.thirtySecSent)
          this.gtag30sec();
      }

      if (!this.totalSecSent)
        this.gtagtotalsec();
    },
  }
</script>

<style lang="scss" scoped>
  @import "../../assets/scss/settings";

  .fave-container {
    padding: 0.7em;;
    background-color: rgba(0, 129, 207, 0.9);
  }

  .favorites {
    overflow-y: auto;

    ol {
      margin-bottom: 0;
      padding-left: 0;
      width: 100%;
      list-style-type: none;

      li {
        margin: 0 1rem 0.5rem 1rem;
        padding: 0.5em;
        border: 5px solid #e7932d;
        border-radius: 0.75rem;;
        &:hover {
          cursor: pointer;
        }
      }

      .active {
        p {
          &:last-child { color: #fff;}
        }
      }
    }

    p {
      margin-bottom: 0;
      color: #fff !important;
      font-size: 0.75em !important;
      font-weight: normal !important;
    }
  }

  .playlist-thumb {
    width: 100%;
    height: auto;
    border: 4px solid #e7932d;
    border-radius: 1rem;
  }

  .delete-icon {
    z-index: 1;
    right: 3%;
    position: absolute;
    padding: 0.35rem;
    color: #fff;
    background-color: $login-orange;
    border-radius: 1rem;
    font-size: 1.3em !important;
    opacity: 0.7;

    &:hover {
      cursor: pointer;
      opacity: 1;
    }
  }

  .no-content {
    .title {
      color: $tooltip-color !important;
      font-family: 'WickedMouse' !important;
    }
    p {
      color: $tooltip-color;
      font-size: 1.25em !important;
    }
  }

  /* Custom Scrollbar */
  .favorites::-webkit-scrollbar {
    width: 12px;
    background-color: rgba(255,255,255,0.5);
    -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,.3);
  }

  .favorites::-webkit-scrollbar-thumb {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, .3);
    background-color: $nav-pink-1;
  }

  /* Extra Small devices */
  @media only screen and (max-width: $xs-max) {
    .playlist-thumb {
      margin-top: 1rem;
      width: 85%;
    }
    .favorites {
      ol {
        li {
          border: 3px solid #e7932d;
        }
      }
      p {
        font-size: 0.85em !important;
      }
    }
    .delete-icon {
      font-size: 1.5em !important;
    }
    .no-content {
      p { font-size: 1em !important; }
    }
  }
  

  /* Small devices (portrait tablets and large phones) */
  @media only screen and (min-width: $sm-min) {
    .playlist-thumb {
      margin-top: 1rem;
      width: 85%;
    }
    .favorites {
      ol {
        li {
          border: 3px solid #e7932d;
        }
      }
      p {
        font-size: 1em !important;
      }
    }
    .delete-icon {
      font-size: 1.5em !important;
    }
  }
  /* Medium devices (landscape tablets) */
  @media only screen and (min-width: $md-min) {
    .playlist-thumb {
      margin-top: 1rem;
      width: 90%;
    }
    .favorites {
      ol {
        li {
          border: 4px solid #e7932d;
        }
      }
      p {
        font-size: 1.25em !important;
      }
    }
    .delete-icon {
      font-size: 1.75em !important;
    }
  }
  /* Large devices (laptops/desktops) */
  @media only screen and (min-width: $lg-min) {
    .playlist-thumb {
      margin-top: 0;
      width: 100%;
    }
  }
  /* Extra large devices (large laptops and desktops) */
  @media only screen and (min-width: $xl-min) {
    .favorites {
      p {
        font-size: 1em !important;
      }
    }
    .delete-icon {
      font-size: 1.25em !important;
    }
    .playlist-thumb {
      margin-top: 0;
      width: 100%;
    }
    .no-content {
      p { font-size: 1.1em !important; }
    }
  }
  @media only screen and (min-width: $special) {}
  @media only screen and (min-width: $xl2-min) {
    .playlist-thumb {
      margin-top: 0;
      width: 100%;
    }
    .no-content {
      p { font-size: 1.25em !important; }
    }
  }
  /* iMac Res */
  @media only screen and (min-width: $xl5-min) {}

</style>