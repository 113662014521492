export default {
  data() {
    return {
      windowHeight          : 0,
      windowWidth           : 0,
      dialogContentHeight   : 0,
      dialogHeaderHeight    : 0,
      contentHeightOverride : 'auto',
    };
  },
  computed : {
    breakpoint() {
      let breakpoint = 'xs';

      if (this.windowWidth >= 1200)
        breakpoint = 'xl';
      else if (this.windowWidth >= 992)
        breakpoint = 'lg';
      else if (this.windowWidth >= 768)
        breakpoint = 'md';
      else if (this.windowWidth >= 576)
        breakpoint = 'sm';
      
      return breakpoint;
    },
    columnItems() {
      let size = 1;
      
      if (this.windowWidth >= 992)
        size = 3;//4;
      else if (this.windowWidth >= 768)
        size = 2; //3;
      else if (this.windowWidth >= 576)
        size = 1; //2;
      
      return size;
    },
    musicColumnItems() {
      let size = 1;

      if (this.windowWidth >= 992)
        size = 6;
      else if (this.windowWidth >= 768)
        size = 5;
      else if (this.windowWidth >= 576)
        size = 4;

      return size;
    },
  },
  watch : {
    windowHeight() {
      this.contentHeightOverride = 'auto';
      this.getDialogHeights();
      this.$nextTick(() => {
        const override = this.getDialogContentHeightOverride();

        if (override != 'auto') {
          this.contentHeightOverride = (override < this.windowHeight) ?
            `${override}px` : 'auto';
        } else
          this.contentHeightOverride = 'auto';
      });
    },
  },
  mounted() {
    this.$nextTick(() => {
      window.addEventListener('resize', this.getWindowHeight);
      window.addEventListener('resize', this.getWindowWidth);

      this.getWindowHeight();
      this.getWindowWidth();
    });
  },
  methods : {

    /**
     * Get Window Height
     *
     * @param event
     */
    // eslint-disable-next-line no-unused-vars
    getWindowHeight(event) {
      this.windowHeight = document.documentElement.clientHeight;
    },

    /**
     * Get Window Width
     *
     * @param event
     */
    // eslint-disable-next-line no-unused-vars
    getWindowWidth(event) {
      this.windowWidth = document.documentElement.clientWidth;
    },

    /**
     * Get Portion
     *
     * @param maxColumnItems
     * @returns {number}
     */
    getPortion(maxColumnItems) {
      let portion = 0;

      switch (maxColumnItems) {
      case 1:
        portion = 12;
        break;
      case 2:
        switch (this.breakpoint) {
        case 'xl':
        case 'lg':
        case 'md':
          portion = 6;
          break;
        case 'sm':
        case 'xs':
          portion = 12;
        }
        break;
      case 3:
        switch (this.breakpoint) {
        case 'xl':
        case 'lg':
          portion = 4;
          break;
        case 'md':
        case 'sm':
          portion = 6;
          break;
        case 'xs':
          portion = 12;
        }
        break;
      case 4:
        // eslint-disable-next-line default-case
        switch (this.breakpoint) {
        case 'xl':
          portion = 3;
          break;
        case 'lg':
          portion = 4;
          break;
        case 'md':
        case 'sm':
          portion = 6;
          break;
        case 'xs':
          portion = 12;
        }
        break;
      }

      return portion;
    },

    /**
     *  Get Modal Dialog Height
     */
    getDialogHeights() {
      this.$nextTick(() => {
        const $header = document.documentElement
          .getElementsByClassName('dialog-header')[0];
        const $content = document.documentElement
          .getElementsByClassName('dialog-content')[0];

        this.dialogHeaderHeight = $header ? $header.clientHeight : 0;
        this.dialogContentHeight = $content ? $content.clientHeight : 0;
      });

    },

    /**
     * Get Dialog Content Height Override
     *
     * @returns {String|Number}
     */
    getDialogContentHeightOverride() {
      return (this.windowHeight >=
        (this.dialogHeaderHeight + this.dialogContentHeight)) ?
        'auto' : (this.windowHeight - this.dialogHeaderHeight);
    },
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.getWindowHeight);
    window.removeEventListener('resize', this.getWindowWidth);
  },
};
