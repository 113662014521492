export default {
  data() {
    return {
      mediaContainerWidth  : 0,
      mediaContainerHeight : 0,
      plistContainerWidth  : 0,
      plistContainerHeight : 0,
    };
  },
  computed : {},
  watch    : {
    mediaContainerWidth : function () {
      // 16:9 ratio
      this.mediaContainerHeight = this.mediaContainerWidth * 0.5625;
    },
    plistContainerWidth : function() {
      // 1:1 ratio
      this.plistContainerHeight = this.plistContainerWidth;
    },
  },
  methods : {

    /**
     * Get Media Container Width
     */
    getMediaContainerWidth() {
      this.$nextTick(() => {
        const $container = this.$el
          .getElementsByClassName('media-container')[0];
        if ($container)
          this.mediaContainerWidth = $container.clientWidth;
        
      });
    },

    /**
     * Get Playlist Container Width
     */
    getPlistContainerWidth() {
      this.$nextTick(() => {
        const $container = this.$el
          .getElementsByClassName('plist-container')[0];
        if ($container)
          this.plistContainerWidth = $container.clientWidth;
      });
    },
  },
  mounted() {
    this.getMediaContainerWidth();
    this.getPlistContainerWidth();
  },
  updated() {
    this.getMediaContainerWidth();
    this.getPlistContainerWidth();
  },

};