import moment from 'moment';

export default {
  filters : {

    /**
     * Truncate filter
     *
     * @param value String to truncate
     * @param max Max string length
     * @returns {string}
     */
    truncate(value, max = 20) {
      if (value && value.length > max)
        value = value.substring(0, (max - 3)) + '...';
      
      return value;
    },

    /**
     * Format time filter
     *
     * @param value Time
     * @returns {string}
     */
    formatTime(value) {
      let result = '';
      const hours = Math.floor(value / 3600);
      const minutes = Math.floor((value - (hours * 3600)) / 60);
      let seconds = value - (hours * 3600) - (minutes * 60);

      // round seconds & exclude remainder
      seconds = parseInt(Math.round(seconds * 100) / 100);

      if (hours > 0) {
        result = (hours < 10 ? '0' + hours : hours) +
          ':' + (minutes < 10 ? '0' + minutes : minutes) +
          ':' + (seconds < 10 ? '0' + seconds : seconds);
      } else {
        result = (minutes < 10 ? '0' + minutes : minutes) + ':' +
          (seconds < 10 ? '0' + seconds : seconds);
      }

      return result;
    },

    /**
     * Level abbreviation filter
     *
     * @param value Grade Level in number
     * @returns {string}
     */
    levelAbbreviation(value) {
      switch (value) {
      case 0:
        return 'Kindergarten';
      case 1:
        return value + 'st Grade';
      case 2:
        return value + 'nd Grade';
      case 3:
        return value + 'rd Grade';
      case 4:
      case 5:
      case 6:
      case 7:
      case 8:
      case 9:
      case 10:
      case 11:
      case 12:
        return value + 'th Grade';
      default:
        return 'None';
      }
    },

    /**
     * Timezone abbreviation filter
     *
     * @param value Timezone's full text
     * @returns {string}
     */
    timezoneAbbreviation(value) {
      switch (value) {
      case 'America/New_York':
        return 'EST/EDT';
      case 'America/Chicago':
        return 'CST/CDT';
      case 'America/Denver':
        return 'MDT';
      case 'America/Phoenix':
        return 'MST No DST';
      case 'America/Los_Angeles':
        return 'PST/PDT';
      case 'America/Anchorage':
        return 'AKST/AKDT';
      case 'America/Adak':
        return 'HDT';
      case 'Pacific/Honolulu':
        return 'HST No DST';
      default:
        return '';
      }
    },

    /**
     * User type filter
     *
     * @param value User Type Number
     * @returns {string}
     */
    userTypeText(value) {
      switch (value) {
      case 1:
        return 'Kid';
      case 2:
        return 'Parent';
      case 3:
        return 'Educator';
      case 4:
        return 'Champion';
      case 5:
        return 'Influencer';
      case 6:
        return 'Organization';
      default:
        return 'Unset';
      }
    },

    /**
     * Format Relative Time (i.e. 2 days ago)
     * @param value Date
     * @returns {string}
     */
    formatRelativeTime(value) {
      return moment.utc(value).fromNow();
    },

    /**
     * Convert Byte to MB
     * @param value
     * @returns {decimal}
     */
    byteToMB(value) {
      return (value / (1024*1024)).toFixed(2);
    },

  },
};